import { useState } from 'react'
import Api from '../../services/Api'
import { navigate } from 'gatsby'
import { saveCartURL } from '../../ApiEndpoints'
import useGetCartSummary from './useGetCartSummary'

function useSaveCart() {
  const [isLoading, setLoading] = useState(false)
  const { mutate: mutateCartSummary } = useGetCartSummary()

  const replaceBCCart = async (bigcommerceCartId) => {
    setLoading(true)
    const res = await replaceCart(saveCartURL(bigcommerceCartId))

    if (res.status === 200) {
      navigate('/cart')
      mutateCartSummary()
    }

    setLoading(false)
  }

  return {
    replaceBCCart,
    isLoading,
  }
}

async function replaceCart(url) {
  const response = await Api.post(url)
  return response
}

export default useSaveCart
